// SF Chamber Blog Listing Filtering
var blogCatFilter = $('#blog-filters #blogCat'),
    blogIssueFilter = $('#blog-filters #blogIssue'),
    blogYearFilter = $('#blog-filters #blogYear'),
    resetFilters = $('#blog-filters #blogReset'),
    paginationHolder = $('#sfc-blog-pagination');

blogCatFilter.on('change', function() {
    gatherVars();
});

blogIssueFilter.on('change', function() {
    gatherVars();
})

blogYearFilter.on('change', function() {
    $('.issues').removeClass('d-none');
    gatherVars();
})

resetFilters.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    $('.issues').addClass('d-none')
    blogCatFilter.val('');
    blogIssueFilter.val('');
    blogYearFilter.val('');
    gatherVars();
}


function gatherVars(page = 1) {

    var cat, issue, year;
    cat = blogCatFilter.find('option:selected').data('id');
    issue = blogIssueFilter.find('option:selected').data('id');
    year = blogYearFilter.find('option:selected').data('id');
    triggerFilter(cat, issue, year, page);
}

if($('#sfc-blog-pagination').length) {
    var prefiltered = helpers.getQueryVariable('category');

    if(prefiltered) {
        blogCatFilter.find('option').each(function() {
            var label = helpers.convertToSlug($(this).text());

            if(label == prefiltered) {
                $(this).prop('selected', true);
            }
        });
    }

    gatherVars();
}


function triggerFilter(cat, issue, year, page = 1) {
    $.post('/wp-admin/admin-ajax.php', {
        action: "blog_filter",
        cat: cat,
        year: year,
        issue: issue,
        page: page,
    }).done(function(r) {

        if (r.success) {
            $('#blog-filter-results').empty();
            // console.log(r);
            var posts = r.data.posts,
                pagination = r.data.pagination;

            for (var i = 0; i < posts.length; i++) {
                var post = posts[i];
                var html = '<article class="col-sm-6 col-xxl-4">';
                html += '<a href="' + post.permalink + '" class="card-article">';
                html += '<div class="img-container has-bg-overlay is-blue">';
                html += post.featuredImage;
                html += '<span class="more-btn">Read More</span>';
                html += '</div>';
                html += '<div class="card-body">';
                html += '<div class="card-content">';
                html += '<span class="heading-6 color-blue-light mb-3">';
                html += post.category;
                html += '</span>';
                html += '<h3 class="card-title color-black mb-3">';
                html += post.title;
                html += '</h3>';
                html += '<p>';
                html += post.excerpt;
                html += '</p>';
                html += '<span class="article-details">';
                html += post.date;
                // console.log(post.readTime);
                if(post.readTime !== null) {
                    html += '<span class="separator" role="presentation">//</span>';
                    html += post.readTime + ' min read';
                }
                html += '</span>';
                html += '</div>';
                html += '</div>';
                html += '</a>';
                html += '</article>';

                $('#blog-filter-results').append(html);
            }
            paginationHolder.html(pagination);
        } else {
            // console.log(r);
            var pagination = r.data.pagination;
            $('#blog-filter-results').html('<div class="no-posts col-12"><h2>No articles found</h2></div>');
            paginationHolder.html(pagination);
        }
    });

}