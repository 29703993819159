// Event Listing Filtering
var eventTypeFilter = $('#sfc-event-filters #eventType'),
    eventDateFilter = $('#sfc-event-filters #eventDate'),
    resetFilters = $('#sfc-event-filters #eventsReset'),
    paginationHolder = $('#sfc-events-pagination');

eventTypeFilter.on('change', function() {
    gatherVars();
});

eventDateFilter.on('change', function() {
    gatherVars();
})

resetFilters.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    eventTypeFilter.val('');
    eventDateFilter.val('');

    gatherVars();
}


function gatherVars(page = 1) {
    var type, date;
    type = eventTypeFilter.find('option:selected').data('id');
    date = eventDateFilter.find('option:selected').data('month');

    triggerFilter(type, date, page);
}

if($('#sfc-event-filters').length) {
    var prefiltered = helpers.getQueryVariable('type');

    if(prefiltered) {
        eventTypeFilter.find('option').each(function() {
            var label = helpers.convertToSlug($(this).text());

            if(label == prefiltered) {
                $(this).prop('selected', true);
            }
        });
    }

    gatherVars();
}

function triggerFilter(type = "", date = "", page = 1) {
    $.post('/wp-admin/admin-ajax.php', {
        action: "event_filter",
        type: type,
        date: date,
        page: page,
    }).done(function(r) {

        if (r.success) {
            $('#sfc-event-filter-results').empty();
            // console.log(r);
            var posts = r.data.posts,
                pagination = r.data.pagination;

            for (var i = 0; i < posts.length; i++) {
                var post = posts[i];
                var html = '<article class="col-sm-6">';
                html += '<a href="' + post.permalink + '" class="card-bio">';
                html += post.featuredImage;
                html += '<div class="card-body">';
                html += '<div class="card-content">'
                html += '<h3 class="card-title color-blue-light">' + post.title + '</h3>';
                html += '<div class="card-date">';
                html += post.eventDate;
                html += '</div>';
                if(post.locationName && post.locationName.length) {
                    html += '<p>' + post.locationName + '</p>';
                }
                html += '<span class="btn btn-link btn-link-arrow btn-blue btn-arrow-next">Learn More</span>';
                html += '</div>';
                html += '</div>';
                html += '</a>';
                html += '</article>';

                $('#sfc-event-filter-results').append(html);
            }
            
            paginationHolder.html(pagination);

        } else {
            // console.log(r);
            var pagination = r.data.pagination;
            $('#sfc-event-filter-results').html('<div class="no-posts col-12"><h2>No events found</h2></div>');
            paginationHolder.html('');
        }
    });

}